'use client'

import { Editor as CoreEditor } from '@tiptap/core'
import { ReactNode, useEffect, useRef, useState } from 'react'
import useOnclickOutside from 'react-cool-onclickoutside'
import { DateRange } from 'react-day-picker'
import { MultiValue } from 'react-select'
import { AsyncMultipleSearchWithSelect } from '~/core/ui/AsyncMultipleSearchWithSelect'
import { AsyncSingleSearchWithSelect } from '~/core/ui/AsyncSingleSearchWithSelect'
import { Button } from '~/core/ui/Button'
import { DashedButton } from '~/core/ui/DashedButton'
import { CloseCircleFill } from '~/core/ui/FillIcons'
import { Input, InputSizeProps } from '~/core/ui/Input'
import { InputRightElement } from '~/core/ui/InputElement'
import { InputGroup } from '~/core/ui/InputGroup'
import { MultipleSelect } from '~/core/ui/MultipleSelect'
import { NativeSelect } from '~/core/ui/NativeSelect'
import { PhoneInput } from '~/core/ui/PhoneInput'
import {
  RangeDatePicker,
  RangeDatePickerSizeProps
} from '~/core/ui/RangeDatePicker'
import {
  IExtraToolbar,
  RichEditor,
  RichEditorSizeProps
} from '~/core/ui/RichEditor'
import {
  IPromiseSearchOption,
  ISelectOption,
  SelectClassControl,
  SelectOptionProps,
  SelectSizeProps
} from '~/core/ui/Select'
import {
  SingleDatePicker,
  SingleDatePickerSizeProps
} from '~/core/ui/SingleDatePicker'
import {
  BehanceIcon,
  CanvaIcon,
  FacebookIcon,
  GithubIcon,
  GitLabIcon,
  InstagramIcon,
  LinkedinIcon,
  OtherIcon,
  XingIcon
} from '~/core/ui/SocialIcons'
import { Textarea, TextAreaSizeProps } from '~/core/ui/TextArea'
import {
  Tooltip,
  tooltipAlignProps,
  tooltipPositionProps
} from '~/core/ui/Tooltip'
import { cn } from '~/core/ui/utils'

interface InlineEditingOptionsProps {
  inlineMode?: 'default' | 'link' | 'editor' | 'drawer'
  children?: ReactNode
  component?: ReactNode
  onClick?: (param: { autoSave: boolean }) => void
  onCancel?: () => void

  tooltipElement?: {
    title: ReactNode
    position?: tooltipPositionProps
    align?: tooltipAlignProps
  }
  tooltipError?: {
    position?: tooltipPositionProps
    align?: tooltipAlignProps
  }
  tooltipActionCancel?: {
    title: string
    position?: tooltipPositionProps
    align?: tooltipAlignProps
  }
  tooltipActionSave?: {
    title: string
    position?: tooltipPositionProps
    align?: tooltipAlignProps
  }
  tooltipAddLinkButton?: {
    title: string
    position?: tooltipPositionProps
    align?: tooltipAlignProps
  }

  edited?: boolean
  setEdited?: (selected: boolean) => void
  loading?: boolean
  showActions?: boolean
  autoSave?: boolean
  destructiveText?: ReactNode
  isDisabledLinks?: boolean
  linkAddButtonClassName?: string
}

const InlineEditingControl = (props: InlineEditingOptionsProps) => {
  const {
    inlineMode = 'default',
    isDisabledLinks = false,
    children,
    onClick,
    onCancel,
    component,
    edited,
    setEdited,
    loading,
    showActions = true,
    tooltipElement,
    tooltipError,
    tooltipActionCancel,
    tooltipActionSave,
    tooltipAddLinkButton,
    autoSave,
    destructiveText,
    linkAddButtonClassName
  } = props
  const componentWrapperRef = useRef<HTMLDivElement>(null)
  const ref = useOnclickOutside(
    () => {
      if (onClick && edited) {
        if (autoSave) {
          onClick({ autoSave: false })
        } else {
          onCancel && onCancel()
        }
      }
    },
    {
      ignoreClass: 'my-ignore-action'
    }
  )

  const renderedActions = () => {
    return (
      <div className="relative float-right flex min-w-[54px] items-center justify-end space-x-1.5 pt-1.5">
        <Tooltip
          content={tooltipActionCancel?.title}
          position={tooltipActionCancel?.position || 'bottom'}
          align={tooltipActionCancel?.align || 'center'}
        >
          <Button
            isDisabled={loading}
            className="shadow-dialog"
            size="xs"
            iconMenus="X"
            type="secondary"
            onClick={() => onCancel && onCancel()}
          />
        </Tooltip>

        <Tooltip
          content={tooltipActionSave?.title}
          position={tooltipActionSave?.position || 'bottom'}
          align={tooltipActionSave?.align || 'center'}
        >
          <Button
            className="shadow-dialog"
            isLoading={loading}
            size="xs"
            iconMenus="Check"
            type="primary"
            htmlType="button"
            onClick={() => onClick && onClick({ autoSave: false })}
          />
        </Tooltip>
      </div>
    )
  }

  const renderedView = () => {
    if (inlineMode === 'link') {
      return (
        <div className="flex flex-wrap gap-y-2 px-2 py-1.5">
          {children}
          <div
            className={cn(
              'relative flex-1',
              isDisabledLinks ? '' : 'cursor-pointer'
            )}
          >
            <div
              className={cn(
                'flex items-center justify-start',
                linkAddButtonClassName
              )}
            >
              {tooltipAddLinkButton?.title ? (
                <Tooltip content={tooltipAddLinkButton?.title}>
                  <DashedButton
                    isDisabled={isDisabledLinks}
                    size="sm"
                    iconMenus="Plus"
                    onClick={() => setEdited && setEdited(true)}
                  />
                </Tooltip>
              ) : (
                <DashedButton
                  isDisabled={isDisabledLinks}
                  size="sm"
                  iconMenus="Plus"
                  onClick={() => setEdited && setEdited(true)}
                />
              )}
            </div>

            {edited ? (
              <div
                ref={ref}
                className="absolute bottom-0 left-0 top-full z-10 mt-1"
              >
                <div className="w-full bg-white">{component}</div>
                {showActions ? renderedActions() : null}
              </div>
            ) : null}
          </div>
        </div>
      )
    }
    if (inlineMode === 'drawer') {
      return (
        <div className="relative flex flex-wrap gap-y-2 px-2 py-1.5 desktop:static">
          {children}
          <div
            className={cn(
              'static flex-1 desktop:relative',
              isDisabledLinks ? '' : 'cursor-pointer'
            )}
          >
            <div
              className={cn(
                'flex items-center justify-start',
                linkAddButtonClassName
              )}
            >
              {tooltipAddLinkButton?.title ? (
                <Tooltip content={tooltipAddLinkButton?.title}>
                  <DashedButton
                    isDisabled={isDisabledLinks}
                    size="sm"
                    iconMenus="Plus"
                    onClick={() => setEdited && setEdited(true)}
                  />
                </Tooltip>
              ) : (
                <DashedButton
                  isDisabled={isDisabledLinks}
                  size="sm"
                  iconMenus="Plus"
                  onClick={() => setEdited && setEdited(true)}
                />
              )}
            </div>

            {edited ? (
              <div
                ref={ref}
                className="absolute bottom-0 right-0 top-full z-10 mt-1 desktop:left-0 desktop:right-auto"
              >
                <div className="w-auto bg-white desktop:min-w-full">
                  {component}
                </div>
                {showActions ? renderedActions() : null}
              </div>
            ) : null}
          </div>
        </div>
      )
    }
    return (
      <>
        {!(edited && inlineMode === 'editor') ? (
          <Tooltip
            classNameConfig={{
              content: !tooltipElement?.title ? 'hidden' : ''
            }}
            content={tooltipElement?.title}
            position={tooltipElement?.position || 'top'}
            align={tooltipElement?.align || 'end'}
          >
            <div
              onClick={() => setEdited && setEdited(true)}
              className="cursor-pointer rounded hover:bg-gray-50"
            >
              {children}
            </div>
          </Tooltip>
        ) : null}

        {edited ? (
          <div
            ref={ref}
            className={cn(
              inlineMode === 'editor'
                ? 'relative'
                : 'absolute bottom-0 left-0 right-0 top-0 z-10'
            )}
          >
            <div ref={componentWrapperRef}>
              {destructiveText && componentWrapperRef.current ? (
                <Tooltip
                  open
                  position={tooltipError?.position || 'top'}
                  align={tooltipError?.align || 'end'}
                  content={destructiveText}
                  container={componentWrapperRef.current}
                >
                  <div className="h-full bg-white">{component}</div>
                </Tooltip>
              ) : (
                <div className="h-full bg-white">{component}</div>
              )}
            </div>
            {showActions ? renderedActions() : null}
          </div>
        ) : null}
      </>
    )
  }

  return <div className="relative">{renderedView()}</div>
}

interface InlineEditingInputProps extends InlineEditingOptionsProps {
  onChange: (value: string | number) => Promise<boolean | undefined>
  onCancelUpdate?: (value: string | number) => void
  value?: string | number
  size?: InputSizeProps
  placeholder?: string
  className?: string
  destructiveText?: ReactNode
  isDisabled?: boolean
  loadingSubmit?: boolean
}

const InlineEditingInput = ({
  size,
  onChange,
  onCancelUpdate,
  children,
  value,
  placeholder,
  className,
  destructiveText,
  isDisabled,
  loadingSubmit,
  ...props
}: InlineEditingInputProps) => {
  const [valueState, setValueState] = useState(value || '')
  const [oldValueState, setOldValueState] = useState(value || '')
  const [edited, setEdited] = useState(false)
  const [loading, setLoading] = useState(loadingSubmit || false)

  useEffect(() => {
    setValueState(value || '')
  }, [value])

  useEffect(() => {
    if (edited) {
      setOldValueState(valueState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edited])

  const handleSave = ({ autoSave = false }: { autoSave: boolean }) => {
    if (onChange && autoSave === false) {
      setLoading(true)
      onChange(valueState).then((result) => {
        if (result) {
          setEdited(false)
        }
        setLoading(false)
      })
    }
  }

  return (
    <InlineEditingControl
      {...props}
      destructiveText={destructiveText}
      onClick={({ autoSave = false }) => handleSave({ autoSave })}
      onCancel={() => {
        setEdited(false)
        setValueState(oldValueState)
        onCancelUpdate && onCancelUpdate(oldValueState)
        if (loading) {
          setLoading(false)
        }
      }}
      component={
        <Input
          autoFocus
          isDisabled={isDisabled}
          className={className}
          size={size}
          placeholder={placeholder}
          value={valueState}
          onChange={setValueState}
          destructive={!!destructiveText}
          onChangeEnter={() => handleSave({ autoSave: false })}
        />
      }
      loading={loading}
      edited={edited}
      setEdited={setEdited}
    >
      {children}
    </InlineEditingControl>
  )
}

InlineEditingInput.displayName = 'InlineEditingInput'

const InlineEditingInputNumber = ({
  size,
  onChange,
  onCancelUpdate,
  children,
  value,
  placeholder,
  className,
  destructiveText,
  isDisabled,
  ...props
}: InlineEditingInputProps) => {
  const [valueState, setValueState] = useState(value || '')
  const [oldValueState, setOldValueState] = useState(value || '')
  const [edited, setEdited] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setValueState(value || '')
  }, [value])

  useEffect(() => {
    if (edited) {
      setOldValueState(valueState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edited])

  const handleSave = ({ autoSave = false }: { autoSave: boolean }) => {
    if (onChange && autoSave === false) {
      setLoading(true)
      onChange(valueState).then((result) => {
        if (result) {
          setEdited(false)
        }
        setLoading(false)
      })
    }
  }

  return (
    <InlineEditingControl
      {...props}
      destructiveText={destructiveText}
      onClick={({ autoSave = false }) => handleSave({ autoSave })}
      onCancel={() => {
        setEdited(false)
        setValueState(oldValueState)
        onCancelUpdate && onCancelUpdate(oldValueState)
        if (loading) {
          setLoading(false)
        }
      }}
      component={
        <Input
          autoFocus
          isDisabled={isDisabled}
          className={className}
          size={size}
          inputType="number"
          placeholder={placeholder}
          value={valueState}
          onChange={setValueState}
          destructive={!!destructiveText}
          onChangeEnter={() => handleSave({ autoSave: false })}
        />
      }
      loading={loading}
      edited={edited}
      setEdited={setEdited}
    >
      {children}
    </InlineEditingControl>
  )
}

InlineEditingInputNumber.displayName = 'InlineEditingInputNumber'

interface InlineEditingInputGroupProps extends InlineEditingOptionsProps {
  onChange: (value: string | number) => Promise<boolean | undefined>
  onCancelUpdate?: (value: string | number) => void
  value?: string | number
  size?: InputSizeProps
  placeholder?: string
  className?: string
  destructiveText?: string
  configClassName?: string
  configPadding?: {
    left: string
    right: string
  }
  renderedElement?: ReactNode
}

const InlineEditingInputGroup = ({
  size,
  onChange,
  onCancelUpdate,
  children,
  value,
  placeholder,
  className,
  destructiveText,
  configClassName,
  configPadding,
  renderedElement,
  ...props
}: InlineEditingInputGroupProps) => {
  const [valueState, setValueState] = useState(value || '')
  const [oldValueState, setOldValueState] = useState(value || '')
  const [edited, setEdited] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setValueState(value || '')
  }, [value])

  useEffect(() => {
    if (edited) {
      setOldValueState(valueState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edited])

  const handleSave = ({ autoSave = false }: { autoSave: boolean }) => {
    if (onChange && autoSave === false) {
      setLoading(true)
      onChange(valueState).then((result) => {
        if (result) {
          setEdited(false)
        }
        setLoading(false)
      })
    }
  }

  return (
    <InlineEditingControl
      {...props}
      destructiveText={destructiveText}
      onClick={({ autoSave = false }) => handleSave({ autoSave })}
      onCancel={() => {
        setEdited(false)
        setValueState(oldValueState)
        onCancelUpdate && onCancelUpdate(oldValueState)
        if (loading) {
          setLoading(false)
        }
      }}
      component={
        <InputGroup
          size={size}
          className={configClassName}
          configPadding={configPadding}
        >
          <Input
            autoFocus
            className={className}
            placeholder={placeholder}
            value={valueState}
            onChange={setValueState}
            destructive={!!destructiveText}
            onChangeEnter={() => handleSave({ autoSave: false })}
          />
          <InputRightElement>{renderedElement}</InputRightElement>
        </InputGroup>
      }
      loading={loading}
      edited={edited}
      setEdited={setEdited}
    >
      {children}
    </InlineEditingControl>
  )
}

InlineEditingInputGroup.displayName = 'InlineEditingInputGroup'

interface InlineEditingTextareaProps extends InlineEditingOptionsProps {
  onChange: (value: string | number) => Promise<boolean | undefined>
  onCancelUpdate?: (value: string | number) => void
  value?: string | number
  size?: TextAreaSizeProps
  placeholder?: string
  className?: string
  destructiveText?: string
  textareaRows?: number
  isDisabled?: boolean
}

const InlineEditingTextarea = ({
  size,
  onChange,
  onCancelUpdate,
  children,
  value,
  placeholder,
  className,
  isDisabled,
  destructiveText,
  textareaRows = 0,
  ...props
}: InlineEditingTextareaProps) => {
  const [valueState, setValueState] = useState(value || '')
  const [oldValueState, setOldValueState] = useState(value || '')
  const [edited, setEdited] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setValueState(value || '')
  }, [value])

  useEffect(() => {
    if (edited) {
      setOldValueState(valueState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edited])

  const handleSave = ({ autoSave = false }: { autoSave: boolean }) => {
    if (onChange && autoSave === false) {
      setLoading(true)
      onChange(valueState).then((result) => {
        if (result) {
          setEdited(false)
        }
        setLoading(false)
      })
    }
  }

  return (
    <InlineEditingControl
      {...props}
      destructiveText={destructiveText}
      onClick={({ autoSave = false }) => handleSave({ autoSave })}
      onCancel={() => {
        setEdited(false)
        setValueState(oldValueState)
        onCancelUpdate && onCancelUpdate(oldValueState)
        if (loading) {
          setLoading(false)
        }
      }}
      component={
        <Textarea
          autoFocus
          isDisabled={isDisabled}
          className={className}
          rows={textareaRows}
          showCount={false}
          size={size}
          placeholder={placeholder}
          value={valueState}
          onChange={setValueState}
          destructive={!!destructiveText}
          onChangeEnter={() => handleSave({ autoSave: false })}
        />
      }
      loading={loading}
      edited={edited}
      setEdited={setEdited}
    >
      {children}
    </InlineEditingControl>
  )
}

InlineEditingTextarea.displayName = 'InlineEditingTextarea'

interface InlineEditingEditorProps extends InlineEditingOptionsProps {
  onChange: (value: string) => Promise<boolean>
  onCancelUpdate?: (value: string) => void
  value?: string
  size?: RichEditorSizeProps
  placeholder?: string
  className?: string
  limit?: number
  showCount?: boolean
  destructiveText?: string
  bubble?: boolean
  localStorageId?: string
  isDisabled?: boolean
  loadingSubmit?: boolean
  extraToolbar?: IExtraToolbar
  openAIWriter?: boolean
  toggleAIWriter?: () => void
  editorRef?: (editor: CoreEditor) => void
}

const InlineEditingEditor = ({
  size,
  onChange,
  onCancelUpdate,
  children,
  value,
  placeholder,
  className,
  limit = 10000,
  showCount = true,
  destructiveText,
  bubble = false,
  localStorageId,
  isDisabled,
  loadingSubmit,
  extraToolbar,
  toggleAIWriter,
  openAIWriter,
  editorRef,
  ...props
}: InlineEditingEditorProps) => {
  const [valueState, setValueState] = useState(value || '')
  const [oldValueState, setOldValueState] = useState(value || '')
  const [edited, setEdited] = useState(false)
  const [loading, setLoading] = useState(loadingSubmit || false)

  useEffect(() => {
    setValueState(value || '')
  }, [value])

  useEffect(() => {
    if (edited) {
      setOldValueState(valueState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edited])

  const prepareLocalStorage = () => {
    // log
  }

  const updateLocalStorage = () => {
    // log
  }

  const handleSave = ({ autoSave = false }: { autoSave: boolean }) => {
    if (onChange && autoSave === false) {
      setLoading(true)
      onChange(valueState).then((result) => {
        if (result) {
          setEdited(false)
        }
        setLoading(false)
      })
    }
  }

  return (
    <InlineEditingControl
      inlineMode="editor"
      {...props}
      destructiveText={destructiveText}
      onClick={({ autoSave = false }) =>
        !openAIWriter ? handleSave({ autoSave }) : null
      }
      onCancel={() => {
        setEdited(false)
        setValueState(oldValueState)
        onCancelUpdate && onCancelUpdate(oldValueState)
        if (loading) {
          setLoading(false)
        }
      }}
      component={
        <RichEditor
          autoFocus={true}
          bubble={bubble}
          className={className}
          size={size}
          placeholder={placeholder}
          content={valueState}
          onChange={setValueState}
          limit={limit}
          showCount={showCount}
          destructive={!!destructiveText}
          isDisabled={isDisabled}
          extraToolbar={extraToolbar}
          toggleAIWriter={toggleAIWriter}
          openAIWriter={openAIWriter}
          editorRef={(editor: CoreEditor) => {
            editorRef && editorRef(editor)
          }}
        />
      }
      loading={loading}
      edited={edited}
      setEdited={setEdited}
    >
      {children}
    </InlineEditingControl>
  )
}

InlineEditingEditor.displayName = 'InlineEditingEditor'

interface InlineEditingPhoneNumberProps extends InlineEditingOptionsProps {
  onChange: (value: string | number) => Promise<boolean>
  onCancelUpdate?: (value: string | number) => void
  value?: string
  size?: InputSizeProps
  placeholder?: string
  className?: string
  destructiveText?: string
  country?: string
}

const InlineEditingPhoneNumber = ({
  size,
  onChange,
  onCancelUpdate,
  children,
  value,
  placeholder,
  className,
  destructiveText,
  country,
  ...props
}: InlineEditingPhoneNumberProps) => {
  const [valueState, setValueState] = useState(value || '')
  const [oldValueState, setOldValueState] = useState(value || '')
  const [edited, setEdited] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setValueState(value || '')
  }, [value])

  useEffect(() => {
    if (edited) {
      setOldValueState(valueState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edited])

  const handleSave = ({ autoSave = false }: { autoSave: boolean }) => {
    if (onChange && autoSave === false) {
      setLoading(true)
      onChange(valueState).then((result) => {
        if (result) {
          setEdited(false)
        }
        setLoading(false)
      })
    }
  }

  return (
    <InlineEditingControl
      {...props}
      destructiveText={destructiveText}
      onClick={({ autoSave = false }) => handleSave({ autoSave })}
      onCancel={() => {
        setEdited(false)
        setValueState(oldValueState)
        onCancelUpdate && onCancelUpdate(oldValueState)
        if (loading) {
          setLoading(false)
        }
      }}
      component={
        <PhoneInput
          countryCodeEditable
          autoFocus
          country={country}
          className={className || 'mt-1.5'}
          size={size}
          onChange={(value) => setValueState(String(value))}
          value={valueState}
          placeholder={placeholder}
          destructive={!!destructiveText}
          onChangeEnter={() => handleSave({ autoSave: false })}
        />
      }
      loading={loading}
      edited={edited}
      setEdited={setEdited}
    >
      {children}
    </InlineEditingControl>
  )
}

InlineEditingPhoneNumber.displayName = 'InlineEditingPhoneNumber'

interface InlineEditingSingleDatePickerProps extends InlineEditingOptionsProps {
  onChange: (value: Date | undefined) => Promise<boolean>
  onCancelUpdate?: (value: Date | undefined) => void
  value?: Date | undefined
  size?: SingleDatePickerSizeProps
  placeholder?: string
  className?: string
  destructiveText?: string
  locale?: string
}

const InlineEditingSingleDatePicker = ({
  size,
  onChange,
  onCancelUpdate,
  children,
  value,
  placeholder,
  className,
  destructiveText,
  locale,
  ...props
}: InlineEditingSingleDatePickerProps) => {
  const [valueState, setValueState] = useState(value)
  const [oldValueState, setOldValueState] = useState(value)
  const [edited, setEdited] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setValueState(value || undefined)
  }, [value])

  useEffect(() => {
    if (edited) {
      setOldValueState(valueState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edited])

  const handleSave = ({ autoSave = false }: { autoSave: boolean }) => {
    if (onChange && autoSave === false) {
      setLoading(true)
      onChange(valueState).then((result) => {
        if (result) {
          setEdited(false)
        }
        setLoading(false)
      })
    }
  }

  return (
    <InlineEditingControl
      {...props}
      destructiveText={destructiveText}
      onClick={({ autoSave = false }) => handleSave({ autoSave })}
      onCancel={() => {
        setEdited(false)
        setValueState(oldValueState)
        onCancelUpdate && onCancelUpdate(oldValueState)
        if (loading) {
          setLoading(false)
        }
      }}
      component={
        <SingleDatePicker
          locale={locale}
          className={className}
          size={size}
          config={{
            onChange: (value: Date | undefined) => {
              setValueState(value)
            },
            value: valueState,
            className: 'my-ignore-action'
          }}
          placeholder={placeholder}
          destructive={!!destructiveText}
        />
      }
      loading={loading}
      edited={edited}
      setEdited={setEdited}
    >
      {children}
    </InlineEditingControl>
  )
}

InlineEditingSingleDatePicker.displayName = 'InlineEditingSingleDatePicker'

interface InlineEditingRangeDatePickerProps extends InlineEditingOptionsProps {
  onChange: (value: DateRange | undefined) => Promise<boolean>
  onCancelUpdate?: (value: DateRange | undefined) => void
  value?: DateRange | undefined
  size?: RangeDatePickerSizeProps
  placeholder?: string
  className?: string
  destructiveText?: string
  locale?: string
}

const InlineEditingRangeDatePicker = ({
  size,
  onChange,
  onCancelUpdate,
  children,
  value,
  placeholder,
  className,
  destructiveText,
  locale,
  ...props
}: InlineEditingRangeDatePickerProps) => {
  const [valueState, setValueState] = useState(value)
  const [oldValueState, setOldValueState] = useState(value)
  const [edited, setEdited] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setValueState(value || undefined)
  }, [value])

  useEffect(() => {
    if (edited) {
      setOldValueState(valueState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edited])

  const handleSave = ({ autoSave = false }: { autoSave: boolean }) => {
    if (onChange && autoSave === false) {
      setLoading(true)
      onChange(valueState).then((result) => {
        if (result) {
          setEdited(false)
        }
        setLoading(false)
      })
    }
  }

  return (
    <InlineEditingControl
      {...props}
      destructiveText={destructiveText}
      onClick={({ autoSave = false }) => handleSave({ autoSave })}
      onCancel={() => {
        setEdited(false)
        setValueState(oldValueState)
        onCancelUpdate && onCancelUpdate(oldValueState)
        if (loading) {
          setLoading(false)
        }
      }}
      component={
        <RangeDatePicker
          locale={locale}
          className={className}
          size={size}
          config={{
            onChange: (value: DateRange | undefined) => {
              setValueState(value)
            },
            value: valueState,
            className: 'my-ignore-action'
          }}
          placeholder={placeholder}
          destructive={!!destructiveText}
        />
      }
      loading={loading}
      edited={edited}
      setEdited={setEdited}
    >
      {children}
    </InlineEditingControl>
  )
}

InlineEditingRangeDatePicker.displayName = 'InlineEditingRangeDatePicker'

interface InlineEditingNativeSelectProps extends InlineEditingOptionsProps {
  onChange: (value?: ISelectOption) => Promise<boolean>
  onCancelUpdate?: (value?: ISelectOption) => void
  value?: ISelectOption
  size?: SelectSizeProps
  placeholder?: string
  options?: Array<ISelectOption>
  classNameOverride?: SelectClassControl
  destructiveText?: string
  creatable?: boolean
}

const InlineEditingNativeSelect = ({
  size,
  onChange,
  onCancelUpdate,
  children,
  value,
  placeholder,
  options = [],
  classNameOverride,
  destructiveText,
  creatable,
  ...props
}: InlineEditingNativeSelectProps) => {
  const [valueState, setValueState] = useState(value)
  const [oldValueState, setOldValueState] = useState(value)
  const [edited, setEdited] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setValueState(value || undefined)
  }, [value])

  useEffect(() => {
    if (edited) {
      setOldValueState(valueState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edited])

  const handleSave = ({ autoSave = false }: { autoSave: boolean }) => {
    if (onChange && autoSave === false) {
      setLoading(true)
      onChange(valueState).then((result) => {
        if (result) {
          setEdited(false)
        }
        setLoading(false)
      })
    }
  }

  return (
    <InlineEditingControl
      {...props}
      destructiveText={destructiveText}
      onClick={({ autoSave = false }) => handleSave({ autoSave })}
      onCancel={() => {
        setEdited(false)
        setValueState(oldValueState)
        onCancelUpdate && onCancelUpdate(oldValueState)
        if (loading) {
          setLoading(false)
        }
      }}
      component={
        <NativeSelect
          classNameOverride={classNameOverride}
          size={size}
          onChange={(newValue) => {
            setValueState(newValue as ISelectOption)
          }}
          placeholder={placeholder}
          value={valueState}
          options={options}
          destructive={!!destructiveText}
          creatable={creatable}
        />
      }
      loading={loading}
      edited={edited}
      setEdited={setEdited}
    >
      {children}
    </InlineEditingControl>
  )
}

InlineEditingNativeSelect.displayName = 'InlineEditingNativeSelect'

interface InlineEditingAsyncSingleSearchWithSelectProps
  extends InlineEditingOptionsProps {
  value?: Array<ISelectOption>
  onChange: (newValue: MultiValue<ISelectOption>) => Promise<boolean>
  onCancelUpdate?: (newValue: MultiValue<ISelectOption>) => void
  size?: SelectSizeProps
  placeholder?: string
  promiseOptions?: (
    params: IPromiseSearchOption
  ) => Promise<{ metadata?: { totalCount: number }; collection: never[] }>
  classNameOverride?: SelectClassControl
  destructiveText?: string
  creatable?: boolean
  cacheOptions?: string
  limit?: number
  loadAsyncWhenOpen?: boolean
}

const InlineEditingAsyncSingleSearchWithSelect = ({
  size,
  onChange,
  onCancelUpdate,
  children,
  value,
  placeholder,
  promiseOptions,
  classNameOverride,
  destructiveText,
  creatable = false,
  cacheOptions = '',
  limit = 25,
  loadAsyncWhenOpen = true,
  ...props
}: InlineEditingAsyncSingleSearchWithSelectProps) => {
  const [valueState, setValueState] = useState(value || [])
  const [oldValueState, setOldValueState] = useState(value || [])
  const [edited, setEdited] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setValueState(value || [])
  }, [value])

  useEffect(() => {
    if (edited) {
      setOldValueState(valueState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edited])

  const handleSave = ({ autoSave = false }: { autoSave: boolean }) => {
    if (onChange && autoSave === false) {
      setLoading(true)
      onChange(valueState).then((result) => {
        if (result) {
          setEdited(false)
        }
        setLoading(false)
      })
    }
  }

  return (
    <InlineEditingControl
      {...props}
      destructiveText={destructiveText}
      onClick={({ autoSave = false }) => handleSave({ autoSave })}
      onCancel={() => {
        setEdited(false)
        setValueState(oldValueState)
        onCancelUpdate && onCancelUpdate(oldValueState)
        if (loading) {
          setLoading(false)
        }
      }}
      component={
        <AsyncSingleSearchWithSelect
          loadAsyncWhenOpen={loadAsyncWhenOpen}
          promiseOptions={promiseOptions}
          classNameOverride={classNameOverride}
          size={size}
          onChange={(newValue) => {
            setValueState(
              newValue ? (Array.isArray(newValue) ? newValue : [newValue]) : []
            )
          }}
          placeholder={placeholder}
          value={valueState}
          destructive={!!destructiveText}
          creatable={creatable}
          cacheOptions={cacheOptions}
        />
      }
      loading={loading}
      edited={edited}
      setEdited={setEdited}
    >
      {children}
    </InlineEditingControl>
  )
}

InlineEditingAsyncSingleSearchWithSelect.displayName =
  'InlineEditingAsyncSingleSearchWithSelect'

interface InlineEditingMultipleSelectProps extends InlineEditingOptionsProps {
  onChange: (value?: Array<ISelectOption>) => Promise<boolean>
  onCancelUpdate?: (value?: Array<ISelectOption>) => void
  value?: Array<ISelectOption>
  size?: SelectSizeProps
  placeholder?: string
  options?: Array<ISelectOption>
  classNameOverride?: SelectClassControl
  destructiveText?: string
  creatable?: boolean
}

const InlineEditingMultipleSelect = ({
  size,
  onChange,
  onCancelUpdate,
  children,
  value = [],
  placeholder,
  options = [],
  classNameOverride,
  destructiveText,
  creatable = false,
  ...props
}: InlineEditingMultipleSelectProps) => {
  const [valueState, setValueState] = useState(value)
  const [oldValueState, setOldValueState] = useState(value)
  const [edited, setEdited] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setValueState(value || [])
  }, [value])

  useEffect(() => {
    if (edited) {
      setOldValueState(valueState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edited])

  const handleSave = ({ autoSave = false }: { autoSave: boolean }) => {
    if (onChange && autoSave === false) {
      setLoading(true)
      onChange(valueState).then((result) => {
        if (result) {
          setEdited(false)
        }
        setLoading(false)
      })
    }
  }

  return (
    <InlineEditingControl
      {...props}
      destructiveText={destructiveText}
      onClick={({ autoSave = false }) => handleSave({ autoSave })}
      onCancel={() => {
        setEdited(false)
        setValueState(oldValueState)
        onCancelUpdate && onCancelUpdate(oldValueState)
        if (loading) {
          setLoading(false)
        }
      }}
      component={
        <MultipleSelect
          classNameOverride={classNameOverride}
          size={size}
          onChange={(newValue) => setValueState(newValue as ISelectOption[])}
          placeholder={placeholder}
          value={valueState}
          options={options}
          destructive={!!destructiveText}
          creatable={creatable}
        />
      }
      loading={loading}
      edited={edited}
      setEdited={setEdited}
    >
      {children}
    </InlineEditingControl>
  )
}

InlineEditingMultipleSelect.displayName = 'InlineEditingMultipleSelect'

interface InlineEditingAsyncMultipleSearchWithSelectProps
  extends InlineEditingOptionsProps {
  value?: Array<ISelectOption>
  onChange: (newValue: MultiValue<ISelectOption>) => Promise<boolean>
  onCancelUpdate?: (newValue: MultiValue<ISelectOption>) => void
  size?: SelectSizeProps
  placeholder?: string
  promiseOptions?: (
    params: IPromiseSearchOption
  ) => Promise<{ metadata?: { totalCount: number }; collection: never[] }>
  classNameOverride?: SelectClassControl
  configSelectOption?: SelectOptionProps
  destructiveText?: string
  creatable?: boolean
  limit?: number
  loadAsyncWhenOpen?: boolean
}

const InlineEditingAsyncMultipleSearchWithSelect = ({
  size,
  onChange,
  onCancelUpdate,
  children,
  value,
  placeholder,
  promiseOptions,
  classNameOverride,
  configSelectOption,
  destructiveText,
  creatable = false,
  limit = 25,
  loadAsyncWhenOpen = true,
  ...props
}: InlineEditingAsyncMultipleSearchWithSelectProps) => {
  const [valueState, setValueState] = useState(value || [])
  const [oldValueState, setOldValueState] = useState(value || [])
  const [edited, setEdited] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setValueState(value || [])
  }, [value])

  useEffect(() => {
    if (edited) {
      setOldValueState(valueState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edited])

  const handleSave = ({ autoSave = false }: { autoSave: boolean }) => {
    if (onChange && autoSave === false) {
      setLoading(true)
      onChange(valueState).then((result) => {
        if (result) {
          setEdited(false)
        }
        setLoading(false)
      })
    }
  }

  return (
    <InlineEditingControl
      {...props}
      destructiveText={destructiveText}
      onClick={({ autoSave = false }) => handleSave({ autoSave })}
      onCancel={() => {
        setEdited(false)
        setValueState(oldValueState)
        onCancelUpdate && onCancelUpdate(oldValueState)
        if (loading) {
          setLoading(false)
        }
      }}
      component={
        <AsyncMultipleSearchWithSelect
          loadAsyncWhenOpen={loadAsyncWhenOpen}
          promiseOptions={promiseOptions}
          classNameOverride={classNameOverride}
          size={size}
          onChange={(newValue) => setValueState(newValue as ISelectOption[])}
          placeholder={placeholder}
          value={valueState}
          configSelectOption={configSelectOption}
          destructive={!!destructiveText}
          creatable={creatable}
        />
      }
      loading={loading}
      edited={edited}
      setEdited={setEdited}
    >
      {children}
    </InlineEditingControl>
  )
}

InlineEditingAsyncMultipleSearchWithSelect.displayName =
  'InlineEditingAsyncMultipleSearchWithSelect'

interface InlineEditingInputLinkProps extends InlineEditingOptionsProps {
  onChange: (value: string | number) => Promise<boolean>
  onCancelUpdate?: (value: string | number) => void
  value?: string | number
  size?: InputSizeProps
  placeholder?: string
  source?: {
    [key: string]: Array<string>
  }
  onDeleteLink?: (linkType: string) => void
  destructiveText?: string
  sourceGroupClassName?: string
  sourceWrapperClassName?: string
}

const InlineEditingInputLink = ({
  size,
  onChange,
  onCancelUpdate,
  children,
  value,
  placeholder = '',
  onDeleteLink,
  destructiveText,
  sourceGroupClassName,
  sourceWrapperClassName,
  ...props
}: InlineEditingInputLinkProps) => {
  const [valueState, setValueState] = useState(value || '')
  const [oldValueState, setOldValueState] = useState(value || '')
  const [edited, setEdited] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setValueState(value || '')
  }, [value])

  useEffect(() => {
    if (edited) {
      setOldValueState(valueState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edited])

  const handleSave = ({ autoSave = false }: { autoSave: boolean }) => {
    if (onChange && autoSave === false) {
      setLoading(true)
      onChange(valueState).then((result) => {
        if (result) {
          setEdited(false)
        }
        setLoading(false)
        setValueState('')
      })
    }
  }

  return (
    <InlineEditingControl
      inlineMode="link"
      {...props}
      destructiveText={destructiveText}
      onClick={({ autoSave = false }) => handleSave({ autoSave })}
      onCancel={() => {
        setEdited(false)
        setValueState(oldValueState)
        onCancelUpdate && onCancelUpdate(oldValueState)
        if (loading) {
          setLoading(false)
        }
      }}
      component={
        <Input
          autoFocus
          size={size}
          placeholder={placeholder}
          value={valueState}
          onChange={setValueState}
          destructive={!!destructiveText}
          onChangeEnter={() => handleSave({ autoSave: false })}
        />
      }
      loading={loading}
      edited={edited}
      setEdited={setEdited}
    >
      <InlineEditingViewLink
        onDeleteLink={onDeleteLink}
        source={props.source}
        sourceGroupClassName={sourceGroupClassName}
        sourceWrapperClassName={sourceWrapperClassName}
      />
    </InlineEditingControl>
  )
}

InlineEditingInputLink.displayName = 'InlineEditingInputLink'

interface InlineEditingViewLinkProps {
  placeholder?: string
  source?: {
    [key: string]: Array<string>
  }
  onDeleteLink?: (linkType: string) => void
  sourceGroupClassName?: string
  sourceWrapperClassName?: string
  isHideDeleteLink?: boolean
}

export const ICONS_SOCIAL = ({
  key,
  ...rest
}: {
  key: string
  size?: number
  className?: string
}): ReactNode => {
  return {
    instagram: <InstagramIcon {...rest} />,
    linkedin: <LinkedinIcon {...rest} />,
    behance: <BehanceIcon {...rest} />,
    xing: <XingIcon {...rest} />,
    canva: <CanvaIcon {...rest} />,
    github: <GithubIcon {...rest} />,
    gitlab: <GitLabIcon {...rest} />,
    facebook: <FacebookIcon {...rest} />,
    portfolio: <OtherIcon {...rest} />,
    others: <OtherIcon {...rest} />
  }[key]
}

const InlineEditingViewLink = ({
  source = {},
  onDeleteLink,
  sourceGroupClassName,
  sourceWrapperClassName,
  isHideDeleteLink
}: InlineEditingViewLinkProps) => {
  return (
    <>
      {source && Object.keys(source || {}).length ? (
        <div
          className={cn(
            'contents min-h-[32px] flex-wrap items-center pr-2',
            sourceWrapperClassName
          )}
        >
          {Object.keys(source).map((item, index) => {
            if (
              (!!source[item] && Array.isArray(source[item])
                ? source[item]
                : []
              ).length === 0
            )
              return
            return (
              <>
                {(source[item] || []).map((iitem, index) => (
                  <Tooltip
                    key={index}
                    content={iitem}
                    classNameAsChild={sourceGroupClassName}
                  >
                    <a
                      href={iitem}
                      target="_blank"
                      className="group relative mr-2 inline-block cursor-pointer"
                    >
                      {ICONS_SOCIAL({ key: item, size: 20 })}
                      {!isHideDeleteLink ? (
                        <button
                          type="button"
                          onClick={(e) => {
                            e.preventDefault()
                            onDeleteLink && onDeleteLink(iitem)
                          }}
                          className="absolute -bottom-0.5 -right-0.5 z-[10] hidden h-3 w-3 rounded-xl border border-solid border-white bg-white group-hover:block"
                        >
                          <CloseCircleFill size={12} />
                        </button>
                      ) : null}
                    </a>
                  </Tooltip>
                ))}
              </>
            )
          })}
        </div>
      ) : null}
    </>
  )
}

export {
  InlineEditingAsyncMultipleSearchWithSelect,
  InlineEditingAsyncSingleSearchWithSelect,
  InlineEditingEditor,
  InlineEditingInput,
  InlineEditingInputNumber,
  InlineEditingInputGroup,
  InlineEditingInputLink,
  InlineEditingMultipleSelect,
  InlineEditingNativeSelect,
  InlineEditingPhoneNumber,
  InlineEditingRangeDatePicker,
  InlineEditingSingleDatePicker,
  InlineEditingTextarea,
  InlineEditingViewLink
}
export type {
  InlineEditingInputProps,
  InlineEditingOptionsProps,
  InlineEditingPhoneNumberProps,
  InlineEditingRangeDatePickerProps,
  InlineEditingSingleDatePickerProps
}

